@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --text-gradient: linear-gradient(to right, #a8284a, #da784b);
  --btn-gradient: linear-gradient(to bottom, #a8284a, #da784b);
  --btn-gradient-left: linear-gradient(to left, #a8284a, #da784b);
  --btn-gradient-disabled: linear-gradient(to bottom, #a8a8a8, #d7d7d7);

  --ticket-title: 1.25rem;
  --ticket-padding: 0 1rem;
  --ticket-bg: white;
  --ticket-border-radius: 10px;
  --ticket-width: 400px;
  --main-height-event-details: 506.25px;
  --main-width-event-details: 900px;

  --skeleton-border-radius: 20px;
  --skeleton-shimmer-color: #eee;
  --skeleton-background-color: #ddd;
  --skeleton-animation-duration: 1.5s;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* html,
body {
  touch-action: manipulation;
}
*/
body {
  overflow: auto !important;
  color: rgb(var(--foreground-rgb));
}

a {
  text-decoration: none;
}

button {
  background-color: initial;
  border: initial;
  color: white;
  display: inline-block;
  font-family: inherit;
  font-size: medium;
  padding: 0;
  margin: 0;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  /* background-color: #ffffff; */
  /* border: 1px solid #cccccc; */
  box-shadow: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: white;
  width: auto;
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  /* -webkit-text-fill-color: #fff !important; */
  /* Change the text color */
}

@font-face {
  font-family: "CyGroteskKeyBold.ttf";
  src: url("/fonts/Cy_Grotesk_Key_Bold.ttf") format("truetype");
}

.PhoneInputInput {
  background-color: transparent !important;
  font-size: 16px;
  color: white;
  border: none;
  padding: 0 1rem;
}

.PhoneInputInput::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  /* Light white color for the placeholder */
}

.PhoneInputInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
  /* Light white color for the placeholder */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* font-family: "Montserrat", sans-serif; */
  scrollbar-width: none;
  line-height: 45px;
  /* background-color: white; */
  scrollbar-color: #888 #f5f5f5;
}

body {
  color: white;
  background-color: black;
}

/* This is for Webkit browsers like Chrome and Safari */
*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
  border: 1px solid #f5f5f5;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Default font size */
html {
  font-size: 16px;
  /* This makes 1rem = 16px */
}

h1 {
  font-size: 2.5rem;
  /* 40px on a default setting */
  font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

h2 {
  font-size: 2rem;
  /* 32px on a default setting */
  font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

h3 {
  font-size: 1.75rem;
  /* 28px on a default setting */
  font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

h4 {
  font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

h5 {
  font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

p {
  font-size: 1rem;
  /* 16px on a default setting */
}

small {
  font-size: 0.875rem;
  /* 14px on a default setting */
}

@media screen and (max-width: 1480px) {
  :root {
    --main-width-event-details: 800px;
    --ticket-width: 400px;
    --main-height-event-details: calc((800px - 320px) * 9 / 16);
    /* Adjust height */
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 15px;
  }

  :root {
    --main-width-event-details: 75vw;
    --ticket-width: 40vw;
    --main-height-event-details: calc(95vw * 9 / 16);
    /* Adjust height */
  }
}

@media screen and (max-width: 1080px) {
  html {
    font-size: 15px;
  }

  :root {
    --main-width-event-details: 85vw;
    --ticket-width: 40vw;
    --main-height-event-details: calc(95vw * 9 / 16);
    /* Adjust height */
  }
}

/* If screen (viewport) is 800px wide or less, set font-size to 15px */
@media screen and (max-width: 800px) {
  html {
    font-size: 15px;
  }

  :root {
    --main-width-event-details: 95vw;
    --ticket-width: 40vw;
    --main-height-event-details: calc(95vw * 9 / 16);
    /* Adjust height */
  }
}

@media screen and (max-width: 750px) {
  :root {
    --main-width-event-details: 95vw;
    --ticket-width: 95vw;
    --main-height-event-details: calc(95vw * 9 / 16);
    /* Adjust height */
  }
}

/* If screen (viewport) is 600px wide or less, set font-size to 13px */
@media screen and (max-width: 600px) {
  html {
    font-size: 15px;
  }

  .BottomSheet__drag-indicator-wrap {
    display: none !important;
  }
}

/* If screen (viewport) is 450px wide or less, set font-size to 10px */
@media screen and (max-width: 450px) {
  html {
    font-size: 13.34px;
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate-svg {
  animation: rotateAnimation 10s linear infinite;
  /* Adjust the duration as needed */
}

/* Add this to your styles file */
@keyframes vibrate {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.vibrating-input {
  animation: vibrate 0.3s ease-in-out;
  border: 1px solid red !important;
}

.otpContainer {
  margin: 5% auto;
  display: flex;
  justify-content: center;
}

.otpInput {
  width: 2.5rem !important;
  height: 2.5rem;
  margin: 0 0.3rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.BottomSheet__drag-indicator-wrap {
  display: none !important;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 600px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 0.7rem;
    padding: 5px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.PhoneInput {
  border: 1px solid #e2e4e9;
  border-radius: 10px;

  padding-left: 15px;
  width: 100%;
}

.signUpPhone .PhoneInput {
  border: 0px solid transparent !important;
}

.div4 .phone_input .PhoneInput {
  border: 1px solid #e2e4e9;
  border-radius: 20px !important;

  padding-left: 15px;
  width: 100%;
}

